/**
 * ProfileOverview
 * Combination of user information and profile dropdown.
 */

.ProfileOverview {
  display: none;

  @include media-breakpoint-up(xl) {
    display: flex;
  }
}

.ProfileOverview-info {
  color: $primary;
  font-weight: bold;
  margin-right: 1em;
  text-align: right;
}

.ProfileOverview-text {
  margin-bottom: 0;
}
