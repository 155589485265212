.patient-data-datum {
  color: $gray-dark;
  font-size: .8125rem;
  font-style: italic;
}

.ewp-grey-label {
  color: $gray-dark;
  font-size: .8125rem;
  font-weight: bold;
}

.ewp-big-label {
  color: $gray-dark;
  font-weight: bold;
  font-size: 1.125rem;
}

.ewp-text {
  color: $gray-dark;
  font-size: .8em;
}

.ewp-blue-label {
  color: $blue-dark;
}

.card-sub-title-border {
  border-bottom: solid .0625rem #2c96f4;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

.ewp-header {
  color: $gray-dark;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
}

.ajax-search-result {
  max-height: 16em;
  overflow-y: auto;
  position: absolute;
  z-index: 10000;
}

.ajax-search-result-inner {
  border: 2px solid #bdbdbb;
  max-height: 16em;
  overflow-y: auto;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px #a3a3a3;
  box-shadow: 1px 1px #a3a3a3;
  background-color: $white;
  position: relative;
}

.ewp-patient-search-result-open {
  margin: 4px 8px;
  border: 2px solid $blue-dark;
  background-color: $white;
  position: relative;
  height: auto;
  max-height: 112px;
  width: 220px;
  overflow: scroll;
  transition: .5s ease-in-out;
  font-size: 1rem;
  text-align: justify;
}

.ewp-patient-search-result {
  margin: .3em .5em;
  border-bottom: 1px solid $gray-dark;
}


button.push_left {

  margin-right: 2% !important;
}

div.case_table_header_item {
  position: relative;
}

div.case_table_header_item:hover {
  cursor: pointer;

  span.table_header_cases_list {
    color: $blue-dark;
  }
}

div.item_selected {
  color: $gray-dark;
}

div.case_table_header_item {

  div.sort_triangle_asc {
    background-image: url('../../images/arrow-down.svg');
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: 100;
    top: 3px;
  }

  div.sort_triangle_desc {
    background-image: url('../../images/arrow-up.svg');
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: 100;
    top: 3px;
  }
}
