/**
 * ContactCard
 */

.ContactCard {
  border: 1px solid $gray-middle;
  font-size: .75rem;
  padding: .5em;
}

.ContactCard-entry {
  display: flex;
}

.ContactCard-heading {
  font-size: inherit;
  font-weight: bold;
  margin-bottom: 0;
}

/**
 * Icons on the left.
 *
 * 1. Icon should be at least as high as one line.
 */
.ContactCard-icon {
  color: $blue-dark;
  margin-right: 1em;
  padding-top: .25em;
  min-height: 1.125rem; /* 1 */
}

.ContactCard-content {
  margin-bottom: 0;
}

.ContactCard-content--eMail {
  word-break: break-all;
}
