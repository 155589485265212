/**
 * Style overwrites for fullcalendar plugin.
 */

body .fc {
  font-size: .875rem;
}

.fc h2 {
  font-size: 1.5em;
}
