/**
 * Page header
 */

.header {
  background: $white;
  width: 100%;
}

.logo {
  display: block;
  background-image: url('../../images/premiafit.jpg');
  background-size: contain;
  height: 2.5rem;
  width: 28.063rem;
  background-repeat: no-repeat;
}

@include media-breakpoint-down(md) {
  .container-space {
    margin-top: 2rem;
  }

  .no-space-header {
    margin-top: -35px !important;
  }
}

@include media-breakpoint-down(xs) {
  .no-space-header {
    margin-top: -75px !important;
  }

  .logo {
    display: block;
    background-image: url('../../images/premiafit.jpg');
    background-size: contain;
    height: 2rem;
    background-repeat: no-repeat;
  }

}
