/**
 * SiteHeader
 */

.SiteHeader {
  background-color: $white;
}

.SiteHeader-inner {
  display: flex;
  padding-bottom: .5em;
  padding-top: .5em;
}

.SiteHeader-item {
  margin-right: 1em;
}

.SiteHeader-item--logo > img {
  height: 2em;
  margin-top: .5em;
}

.SiteHeader-item--last {
  margin-left: auto;
  margin-right: initial;
}
