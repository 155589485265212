@include media-breakpoint-down(md) {
  .size {
    display: block;
    margin: .125rem;
    position: absolute;
    z-index: 1000;
    float: left;
    padding: .5rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175);
    min-width: 23rem !important;
    transform: translate3d(-203px, -8px, 23px) !important;
    top: 5px !important;
    left: -94px !important;
    will-change: transform !important;

  }
}

.doctorname {
  color: $blue-dark;
  font-size: 1rem;
  font-weight: bold;
}
