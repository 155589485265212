/**
 * SiteFooter
 */

$site-footer-background-color: $white;
$site-footer-padding: 1rem;

.SiteFooter {
  font-size: .875rem;
  padding: 0 0 $site-footer-padding;
}

.SiteFooter-inner {
  display: flex;
  align-items: center;
}

.SiteFooter-logo {
  display: none;

  @include media-breakpoint-up(md) {
    display: initial;
    margin-right: 1em;
  }
}

.SiteFooter-logo > img {
  height: 2em;
}

.SiteFooter-links {
  margin-left: auto;

  .LinkList-link {
    color: $gray-dark;
    margin-left: 1.5em;
    font-size: 1em;
  }
}
