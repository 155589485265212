/**
 * Text
 * Normal text paragraph.
 */

.Text {
  color: $primary;
}

.Text--small {
  font-size: .875rem;
}

.Text:last-child {
  margin-bottom: 0;
}

.label-gray {
  color: $gray-dark;
}
