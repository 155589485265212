.modal-dialog.modal-700 {
  max-width: 700px;
}

.modal-content {
  position: relative;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  background-clip: padding-box;
  outline: 0;
}

.modal-header {
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $blue-dark;
}

.modal-header::before,
.modal-header::after {
  display: table;
}

.modal-header::after {
  clear: both;
}

.modal-header .close {
  margin: 0;
  padding: 0;
}


.modal-title {
  margin: 0;
  color: $blue-dark;
  line-height: 1.42857;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid $blue-dark;
}

.modal-footer::before,
.modal-footer::after {
  display: table;
  content: ' ';
}

.modal-footer::after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-dialog {
  margin: 30px auto;
  max-width: 1200px;
}

.modal-body .assistance-form-desc {
  margin-bottom: 10px;
}

.ewp-diagram-today {
  color: $red;
}

.ewp-diagram-title {
  @extend .ewp-grey-label;
}

.patient-by-status .modal-dialog {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

/* workaround to avoid jumping sidebar boxes */
body.modal-open {
  overflow: visible;
}
