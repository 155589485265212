/**
 * ContactList
 */

.ContactList {
  margin-bottom: 0;
  padding-left: 0;
}

.ContactList-item {
  margin-top: 1.5rem;
}

.ContactList-heading {
  color: $gray-middle;
  font-weight: normal;
  margin-bottom: 0;
}

.ContactList-sublist {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.ContactList-subitem {
  padding-left: .25rem;
  padding-right: .25rem;
}
