/* comfortaa-300 - latin */
@font-face {
  font-family: 'Comfortaa';
  font-weight: 300;
  src: local('Comfortaa Light'),
  url('../../../fonts/comfortaa/comfortaa-v11-latin-300.woff2') format('woff2'),
  url('../../../fonts/comfortaa/comfortaa-v11-latin-300.woff') format('woff');
}

/* comfortaa-regular - latin */
@font-face {
  font-family: 'Comfortaa';
  font-weight: 400;
  src: local('Comfortaa Regular'),
  url('../../../fonts/comfortaa/comfortaa-v11-latin-regular.woff2') format('woff2'),
  url('../../../fonts/comfortaa/comfortaa-v11-latin-regular.woff') format('woff');
}

/* comfortaa-700 - latin */
@font-face {
  font-family: 'Comfortaa';
  font-weight: 700;
  src: local('Comfortaa Bold'),
  url('../../../fonts/comfortaa/comfortaa-v11-latin-700.woff2') format('woff2'),
  url('../../../fonts/comfortaa/comfortaa-v11-latin-700.woff') format('woff');
}
