// colors
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);

.h3 {
  font-size: 1rem;
}

$table-select-color: rgb(150, 203, 234);
$table-color: $black;

$blue-dark: rgb(33, 150, 243);
$blue-light: rgb(90, 182, 255);
$blue-black: rgb(13, 69, 113);

$gray-middle: rgb(152, 161, 180);
$gray-light: rgb(241, 241, 241);
$gray-dark: rgb(90, 102, 118);
$gray-white: $gray-light;

$red: #ff0000;
// fonts
$font1: 'Open Sans', sans-serif;
$font2: 'Comfortaa', sans-serif;

$font-special-headers: $font2;

/**
 * Sizes
 */

$footer-height: 2.25rem;

$site-header-height: 4rem;
