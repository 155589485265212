h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -.01875rem;
}

// specials
.header-special {
  @extend .text-center;
  font-family: $font-special-headers;
  font-size: 1.5625rem !important;
  color: $blue-dark;
}

.header-special-2 {
  @extend .text-center;
  font-family: $font-special-headers;
  color: $blue-dark;
}

.kontakt-header-special {
  @extend .text-center;
  font-family: $font-special-headers;
  font-size: 1.2rem;
  color: $blue-dark;
}

.kontakt-header-special-2 {
  @extend .text-center;
  font-family: $font-special-headers;
  font-size: .825rem;
  color: $blue-dark;
}
