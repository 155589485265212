@include media-breakpoint-up(md) {
  .modal-body .assistance-subject .form-group #callback_assistance_how_to_help .form-check {
    width: 50%;
    height: 20px;
  }

  .call_service_modal .modal-dialog {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
}

@include media-breakpoint-down(md) {
  .call_service_modal .modal-dialog {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-body .assistance-subject .form-group #callback_assistance_how_to_help .form-check {
    width: 100%;
    height: 20px;
  }
}

.modal-body .news-date {
  font-weight: bold;
  margin-bottom: .7em;
}

.modal-body .assistance-label {
  font-weight: bold;
  color: $blue-dark;
}

.modal-body .assistance-form-desc {
  margin-bottom: 10px;
  color: $gray-dark;
}

.modal-body .assistance-phone-group {
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.modal-body .assistance-phone-group .assistance-clinic-contact {
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.modal-body .assistance-phone-group .assistance-clinic-contact .assistance-clinic-contact-field {
  font-weight: bold;
  color: $blue-dark;
}

.modal-body .assistance-time-range {
  display: flex;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.modal-body .assistance-time-range .assistance-time-range-label {
  padding-right: 10px;
}

.modal-body .assistance-time-range #callback_assistance_timespan {
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: flex-end;
}

.modal-body .assistance-time-range #callback_assistance_timespan .checkbox {
  margin-left: 10px;
}

.modal-body .assistance-subject .form-group #callback_assistance_how_to_help {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-end;
}

.modal-content .modal-footer .left {
  float: left;
}

.assistance-subject {
  .form-group {
    #callback_assistance_how_to_help {
      align-items: flex-end;

      .checkbox {
        width: 100%;
        height: 20px;
      }
    }
  }
}


