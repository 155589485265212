.first-clinic {
  background: url('../../images/logo-premiafit.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 14rem;
}

.login-image {
  max-width: 100%;
}

.avatar {
  display: block;
  background: url('../../images/avatar.svg');
  background-size: auto auto;
  height: 8rem;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  width: 8rem;
}

.images-size {
  display: block;
  max-width: 100%;
  background-size: auto auto;
  height: 8rem;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
}

@include media-breakpoint-down(lg) {
  .first-clinic {
    background: url('../../images/logo-premiafit.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 14rem;
  }
}

@include media-breakpoint-up(lg) {
  .polavis {
    display: block;
    width: 100%;
    background: url('../../images/polavis_clinic.png');
    background-size: contain;
    height: 17rem;
    margin-left: auto;
    margin-right: auto;
    background-repeat: round;
  }
}

@include media-breakpoint-down(lg) {
  .polavis {
    display: block;
    width: 100%;
    background: url('../../images/polavis_clinic.png');
    background-size: contain;
    height: 17rem !important;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat !important;
  }
}

@include media-breakpoint-down(xs) {
  .polavis {
    display: block;
    width: 100%;
    background: url('../../images/polavis_clinic.png');
    background-size: contain;
    height: 12rem !important;
    margin-left: auto;
    margin-right: auto;
    background-repeat: round;
  }
}





