.card {
  @extend .mb-3;
}

.card-title {
  @extend .mb-0;
  padding: 1rem 1.25rem .125rem;
}

.card-fix {
  @extend .card;
  height: 217px;
}
