.ewp-dashborad-block {
  margin-bottom: .7em;
}

.ewp-diagram-title {
  text-align: center;
}

#ewp-diagram-modal .modal-header .main-title-font {
  color: $white;
}

#ewp-diagram-modal .modal-dialog > .modal-content > .modal-body {
  display: inline;
  min-height: 15em;
  font-size: 12px;
}

#ewp-diagram-modal .modal-dialog > .modal-content > .modal-body > div:first-child,
#ewp-diagram-modal .modal-dialog > .modal-content > .modal-body > div:nth-child(2) {
  overflow-wrap: break-word;
}

#ewp-diagram-modal .modal-dialog > .modal-content > .modal-body > div:nth-child(3) {
  float: right;
  width: 15%;
  text-align: center;
}

#ewp-diagram-modal .modal-dialog > .modal-content > .modal-body > div:nth-child(3) > div {
  background-color: $red;
  color: $white;
  padding: 5px;
}

#ewp-diagram-modal .modal-dialog > .modal-content > .modal-body > div.news-text {
  width: 100%;
}

#ewp-diagram-modal .modal-dialog > .modal-content > .modal-body > div.news-date {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 5px;
}

.fa-red {
  color: $red;
}
