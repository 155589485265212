/**
 * LinkList component
 *
 * Usage:
 * <div class="LinkList">
 *   <ul class="LinkList-list">
 *     <li class="LinkList-item">
 *       <a class="LinkList-link" href="...">
 *         link text
 *       </a>
 *     </li>
 *     ...
 *   </ul>
 * </div>
 */

$linklist-margin: 1em;

.LinkList-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 0;
  margin-left: -($linklist-margin / 2);
  margin-right: -($linklist-margin / 2);
  padding-left: 0;
}

.LinkList-item {
  margin-left: $linklist-margin / 2;
  margin-right: $linklist-margin / 2;
}

/**
 * Variants
 */

.LinkList--rightAlign > .LinkList-list {
  justify-content: end;
  text-align: right;
}
