/**
 * Layout
 * Determines the general page layout.
 */

.Layout-main {
  margin-bottom: $footer-height + 2rem;
}

/* fix for jumping content when opening modal forms */
body,
.modal {
  padding-right: inherit !important;
}
