#mail_user_selector {
  background: $white;
  border: 1px solid $blue-dark;
  padding: 5px;
}

.modal-content .modal-footer .left {
  float: left;
}

#mail_user_selector .mail_user {
  padding: .3em 1em;
}

#mail_user_selector .mail_user:hover,
#mail_user_selector .mail_user:focus {
  background-color: $blue-light;
  color: $white;
  cursor: pointer;
}

ol,
ul {
  list-style: none;
}

.no-text-mail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected {
  border: 1px solid $blue-dark;
  padding: 5px;
  color:  $blue-dark;
}

.selected:hover {
  color: $blue-dark;
}

/**
 * Remove attachment buttons.
 */
.no-text-mail {
  //white-space: nowrap;
  width: auto;
  overflow: hidden;
}

.no-text-mail-table {
  white-space: nowrap;
  width: 12em;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include media-breakpoint-down(xs) {
  .no-text-mail-table {
    white-space: nowrap;
    width: 5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
