.ewp-clickable,
.nav > li {
  cursor: pointer;
}

.btn {
  cursor: pointer;
}

.success {
  color: $green;
}

.danger {
  color: $red;
}

.disabled {
  color: $gray-middle;
}
