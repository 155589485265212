.notiz_modal .modal-dialog {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

@include media-breakpoint-down(md) {
  .notiz_modal .modal-dialog {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
