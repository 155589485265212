
/**
 * Variants
 */

// Inbox variant

.MailList--inbox .MailList-item--unread {
  font-weight: bold;
}
