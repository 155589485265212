/**
 * Not in use at the moment.
 */

.logo-clinic {
  width: 100%;
  background-image: url('../../images/logo-ehv.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 90px;
}
