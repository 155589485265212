/**
 * Legend
 * e.g. for fullcalendar plugin
 */

.Legend {
  font-size: .875rem;
}

.Legend-color--agreed {
  color: #008000;
}

.Legend-color--requested {
  color: #ffa500;
}
