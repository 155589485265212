.profile-bottom .app_dashboard::before,
.profile-bottom .app_cases_list::before {
  border: 1px solid #20699c;
}

.profile-page .profile-start-page #user_start_page {
  display: flex;
  align-items: baseline;
}

.statistics {
  background-size: 100% 100%;
  height: 150px;
  width: 285px;
}

.cases_list {
  background-size: 100% 100%;
  height: 150px;
  width: 285px;
}

div.invis-required-container > div > label {
  display: none;
}

.profile-page .profile-start-page #user_start_page label.required.app_cases_list::before {
  background-image: none;
  border: 0;
}

.profile-page .profile-start-page #user_start_page label.required.app_dashboard::before {
  background-image: none;
  border: 0;
}

#app_cases_list_image_area {
  background-image: url('../../images/profile_start_option_statistics.png');
  background-size: 100% 100%;
  height: 123px;
  width: 242px;
  border: solid 1px $black;
}

#app_dashboard_image_area {
  background-image: url('../../images/profile_start_option_patient_list.png');
  background-size: 100% 100%;
  height: 123px;
  width: 242px;
  border: solid 1px $black;
}

@include media-breakpoint-up(xs) {
  .frequency_weekly {
    padding-right: 205px !important;
  }

  .profile-page .profile-start-page #user_start_page .app_cases_list {
    padding-right: 193px !important;
  }
}

@include media-breakpoint-down(xs) {
  .frequency_weekly {
    padding-right: 50px !important;
  }

  .profile-page .profile-start-page #user_start_page .app_cases_list {
    padding-right: 50px !important;
  }
}
