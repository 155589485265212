/**
 * MailAttachment
 * Mail attachment component.
 */

.MailAttachment {
  display: flex;
  margin-bottom: .5rem;
}

.MailAttachment-input {
  margin-right: 1em;
}

.MailAttachment-delete {
  margin-left: auto;
}
