/**
 * 1. Always show scrollbar so there are no jumps between page changes.
 */

:root {
  font-size: 1rem;
  overflow-y: scroll; /* 1 */
}

a:visited {
  text-decoration: none;
}

a[data-type='modal'] {
  cursor: pointer;
}
