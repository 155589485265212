/**
 * Table
 * General table component.
 */

$table-font-size: .75rem;
$table-head-font-size: .75rem;
$table-head-color: $gray-600;

.Table {
  font-size: $table-font-size;
}

.Table tr {
  border-bottom: 1px solid $gray-400;
}

.Table th {
  color: $table-head-color;
  font-size: $table-head-font-size;
  font-weight: bold;
}

/**
 * 1. We need to get a higher specificity than the DataTables Bootstrap 4 style.
 */

table.Table {
  border-collapse: collapse !important; /* 1 */
}

/**
 * Table-lengthSelect
 */

.Table-lengthSelect {
  font-size: .75rem;
  height: 1.8125rem;
  padding-bottom: .3rem;
  padding-top: .3rem;
}

/**
 * Table cells
 *
 * 1. Make text-overflow: ellipsis work in table cells.
 *    See https://stackoverflow.com/questions/9789723/css-text-overflow-in-a-table-cell
 */

.Table-cell--truncate {
  max-width: 0; /* 1 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Table-cell--eMail {
  word-break: break-all;
}

.Table--clickable .Table-row {
  cursor: pointer;
}

/**
 * DataTables overwrites
 *
 * 1. Make pagination controls responsive.
 */

.dataTables_wrapper {
  padding-left: 0;
  padding-right: 0;
};

.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate {
  font-size: $table-font-size;
}

@include media-breakpoint-down(sm) {
  div.dataTables_wrapper div.dataTables_length {
    text-align: left;
  }

  div.dataTables_wrapper div.dataTables_filter {
    text-align: right;
  }
}

@include media-breakpoint-down(md) { /* 1 */
  .page-item.previous,
  .page-item.next {
    display: none;
  }

  .page-item:nth-child(2) > a {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .page-item:nth-last-child(2) > a {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }
}
