@include media-breakpoint-down(md) {
  .form-control::placeholder:hover {
    color: $blue-dark;
    opacity: .8;
    background: $gray-white;
    font-size: .8125rem;
  }
}

.form-group {
  input {
    margin: 0;
    font-weight: 600;
    flex-wrap: nowrap;
    padding-bottom: .5rem;

    &::placeholder {
      color: $blue-dark;
      opacity: .8;
      background: $gray-white;
    }
  }

  label {
    color: $gray-dark;
    font-size: .75rem;
    font-weight: bold;
  }

  textarea {
    //   border-bottom: solid .0625rem $gray-light;
    background-color: $gray-white;
  }

  form-check_extend {
    display: inline-block;
  }
}

.line {
  margin-top: 15px;
  border-top: 1px solid $gray-light;
  padding-bottom: .25rem;
}

.input-search::after {
  @extend .fa, .fa-search;
  content: '\f002';
}

.fa-color {
  color: $blue-dark;
}

.form-check-input {
  position: relative !important;
}

.col-form-legend {
  color: $gray-dark;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}

.form-control-label {
  @extend .ewp-grey-label;
}

.form-control-file {
  background-color: transparent !important;
}

.form-control:disabled,
.form-control[readonly] {
  color: $gray-dark;
}

.form-control::placeholder {
  color: $blue-dark;
  opacity: .8;
  background: $gray-white;
}

.form-control::placeholder:hover {
  color: $blue-dark;
  opacity: .8;
  background: $gray-white;
}

.form-check {
  padding-left: 0 !important;
}

.ewp-file-upload .ewp-file-input-button {
  background-color: $blue-dark;
  border-color: $blue-dark;
}

.form-control-ext {
  border: 0;
  border-radius: 0;
  font-size: .8125em;
  width: 100%;
}

.required::after {
  content: '*';
}

.textarea-news {
  height: 100px;
}

.form-control {
  background-color: $gray-light !important;
}

.cura_error_message {
  color: $red;
}

input[type='file'] {
  display: initial;
}

input[type='range'] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

#user_clinics > div.checkbox {
  display: inherit !important;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: $red;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  padding: 10px !important;
  border-radius: 4px;
}

.no-margin {
  margin: 0;
}

.mr-5 {
  margin-right: 5px;
}

.full-width {
  width: 100%;
}

.d-flex {
  display: flex;
}

.text-without-form {
  color: $gray-dark;
  font-size: .75rem;
}

#admission_attachments,
#admission_attachmentTransfer {
  display: block;
}

.text-gray,
.text-gray-bold {
  color: #4e5768;
  font-size: .75rem;
}

.text-gray-bold {
  font-weight: bold;
}

.form-admin-hf {
  .no-facility-user {
    display: none;
  }
}

.form-radio-block {
  display: block;
}

.form-radio-text-part1 {
  display:table-cell;
  padding-right:0.5em;
}

.form-radio-text-part2 {
  display: table-cell;
  width: 100%;
  padding-right: 0.5em;
}

.form-radio-text-part3 {
  display:table-cell;
}

.form-control-inline {
  display: inline !important;
}

.form-radio-inline > .radio {
  display: inline;
  padding-right: 0.5em;
}

/* Normal text size for radio buttons and checkboxes */

.form-radio-text-part1 label,
.form-radio-text-part2 label,
.form-radio-text-part3 label,
.form-group .radio label,
.form-group .checkbox label {
  font-weight: normal;
  font-size: 1em;
}

/* Remove star after "reuired" radio button labels */

.form-group .radio label.required::after {
  content: '';
}
