.footer {
  display: flex;
  background: $white;
  border-top: solid .0625rem $white;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  color: $blue-dark;
  position: fixed;
  height: $footer-height;
  z-index: 10;

  a {
    color: $blue-dark;
    font-size: .75rem;
    font-weight: 300;
  }
}
