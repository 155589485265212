/**
 * Variables
 */
@import 'variables/app';
@import 'variables/bootstrap';

/**
 * Vendor
 */
@import '~bootstrap/scss/bootstrap';
@import '~font-awesome/scss/font-awesome';
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~jquery-ui-themes/themes/smoothness/theme.css';
@import '~fullcalendar/dist/fullcalendar.css';
@import '~highcharts/css/highcharts';
@import '~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4';

// base
@import 'base/fonts';
@import 'base/typo';
@import 'base/base';


// layout
@import 'layout/image';
@import 'layout/bootstrap-extension';
@import 'layout/form_callback_assistance';
@import 'layout/form_passowrt';
@import 'layout/mail';
@import 'layout/block_profile';
@import 'layout/modal-dialog';
@import 'layout/notitz_modal';
@import 'layout/dashbord';
@import 'layout/kontakt';
@import 'layout/profile';
@import 'layout/layout';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/navigation';
@import 'layout/sub-navigation';
@import 'layout/forms';
@import 'layout/news';
@import 'layout/card-login';
@import 'layout/tables';
@import 'layout/clinic';
@import 'layout/menu';
@import 'layout/meeting_typ';
@import 'layout/diagnosis_detail';
@import 'layout/patient';
@import 'layout/fullcalendar';



// components
@import 'components/app-menu';
@import 'components/button';
@import 'components/card';
@import 'components/collapsible';
@import 'components/contact-card';
@import 'components/contact-list';
@import 'components/default-card';
@import 'components/legend';
@import 'components/link-list';
@import 'components/login-page';
@import 'components/mail';
@import 'components/mail-attachment';
@import 'components/mail-list';
@import 'components/mobile-menu';
@import 'components/profile-overview';
@import 'components/site-header';
@import 'components/site-layout';
@import 'components/site-footer';
@import 'components/narrow-container';
@import 'components/table';
@import 'components/text';
@import 'components/mail-sytle';

