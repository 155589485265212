.no-text-admission {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-block {
  position: relative;
  height: auto;
}

.message-block article {
  position: relative;
  height: auto;
  max-height: 105px;
  overflow: hidden;
  -webkit-transition:  .5s ease-in-out;
  -moz-transition:  .5s ease-in-out;
  transition:  .5s ease-in-out;
  font-size: .8125rem;
  text-align: justify;
}

.message-block article.expand {
  max-height: 1000px;
  overflow: hidden;
  text-overflow: ellipsis;

}

@include media-breakpoint-up(lg) {
  .text-cases {
    display: inline-block;
    min-width: 11rem;
  }

  .text-cases-width {
    display: inline-block;
    min-width: 16rem;
  }

  .no-text {
    white-space: nowrap;
    width: auto;
    overflow: hidden;
  }

  .no-text-cases {
    white-space: nowrap;
    width: 8em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@include media-breakpoint-down(lg) {
  .text-cases-down::after {
    content: ': ';
  }

  .st1 {
    display: none;
  }

  .no-text {
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .no-text-form {
    color: $gray-dark;
    white-space: nowrap;
    width: 20em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .no-text-cases {
    white-space: nowrap;
    width: 20em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ewp-text {
    color: $gray-dark;
    font-size: 1rem;
    // width: 20em;
  }
}

@include media-breakpoint-down(xs) {
  .no-text-cases {
    white-space: nowrap;
    width: 5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .no-text-meeting {
    white-space: nowrap;
    width: 5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .no-text-admission {
    white-space: nowrap;
    width: 5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .st-admission {
    display: none;
  }
}

.booking-table {
  width: 50%;
}
