/**
 * MobileMenu
 */

$mobile-menu-width: 18rem;
$mobile-menu-padding: 1rem;

.MobileMenu {
  min-width: $mobile-menu-width;
  padding: $mobile-menu-padding;
}

.MobileMenu-item {
  margin-bottom: .5rem;
  margin-top: .5rem;
}

.MobileMenu-link:link {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}

.MobileMenu-label {
  color: $gray-700;
  font-size: .75rem;
  margin-top: .25rem;
}
