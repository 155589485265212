/**
 * Mail
 * Component that shows an email, including subject and from to fields
 *
 * 1. Minify space between paragraphs within HTML emails.
 */

.Mail-content {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: circle;
  }
}

