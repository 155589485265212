/**
 * Button-related styles.
 */

.btn-sm {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .8125rem;
}

.blue {
  color: $blue-dark;
}

.btn-group-border {
  .btn {
    border: 1px solid $blue-dark;
    color: $blue-dark;
  }
}
