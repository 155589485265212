/**
 * AppMenu
 *
 * 1. Specific selector needed to precede Bootstrap style.
 */

.AppMenu-list {
  margin: -.5em;
}

.AppMenu-item {
  margin: .5em;
  position: relative;

  .counter {
    position: absolute;
    top: -5px;
    right: -10px;
    background: $red;
    border-radius: 50%;
    color: $white;
    font-size: .75em;
    padding: 1px 5px;
    font-style: normal;
    font-family: $font1;
  }
}

.AppMenu .AppMenu-link { /* 1 */
  border-radius: 3px;
  color: $white;
  font-size: .875rem;
  padding: .25em 1em;
  background-color: $primary;
  font-family: $font2;
}
