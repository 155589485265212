/**
 * Collapsible
 * Collapsible card component built on Bootstrap's card and collapse components.
 *
 * 1. Fade separator between header and body when expanding/collapsing.
 * 2. Animate icon rotation when expanding/collapsing.
 */

$collapsible-transition-duration: .35s;
$collapsible-header-line-height: 1.5;

/**
 * Main class
 */
.Collapsible {
  margin-bottom: 1rem;
}

/**
 * Link enclosing the whole header.
 */

.Collapsible-link:link {
  text-decoration: none;
}

/**
 * Card header
 */

.Collapsible-header {
  display: flex;
  background-color: initial;
  transition: transform $collapsible-transition-duration; /* 1 */
  border-bottom-color: transparent;
}

/**
 * Title in the header
 */

.Collapsible-title {
  color: inherit;
  font-size: 1.63em;
  font-weight: bold;
  line-height: $collapsible-header-line-height;
  margin-right: .5em;
}

/**
 * Icon on the right
 */

.Collapsible-icon {
  margin-left: auto;
  transition: transform $collapsible-transition-duration; /* 2 */
  font-size: 1.63em;
}

/**
 * State: collapsed
 */
.Collapsible-link.collapsed .Collapsible-icon {
  transform: rotate(90deg); /* 2 */
}
