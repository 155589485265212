/**
 * DefaultCard
 *
 * Extends Bootstraps card.
 *
 * 1. We have to overwrite another !important property here.
 */

.DefaultCard {
  margin-bottom: initial;
}

.DefaultCard-body {
  @include media-breakpoint-up(sm) {
    padding: 2rem;
  }
}

.DefaultCard-title {
  border-bottom: initial;
  color: $primary;
  font: 1.45rem $font2;
  margin-bottom: 2rem !important; /* 1 */
  padding: initial;
}
