x.time-table {
  width: 100%;

  select {
    margin: 0 5px;
  }
}

.time-days {
  width: 25%;
}

.time-beg {
  width: 32%;
  text-indent: 5px;
}

.time-se {
  width: 11%;
  text-align: center;
}

.time-end {
  width: 32%;
  text-indent: 5px;
}

.time-sep {
  text-align: center;
  font-weight: 900;
}

.ewp-grey-label-meeting-titel {
  color: $gray-dark;
  font-size: 12px;
  font-weight: bold;
}

.ewp-grey-label-meeting-text {
  color: $gray-dark;
  font-size: 10px;
}
