/**
 * NarrowContainer
 * Basically the same as the Bootstrap container, but a narrower one.
 */

.NarrowContainer {
  @include make-container;
  @include make-container-max-widths((
      sm: 540px,
      md: 720px,
      lg: 720px,
      xl: 720 px
  ))
}
